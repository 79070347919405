:root {
  --accent-color: #4040E0;
  --accent-color-transparent: #0000E030;
  --accent-color-invert: #A0A040;
  --accent-color-transparent-invert: #A0A04030;
  --red-color: #C80000;
  --red-color-invert: #37FFFF;
  --badge-color: #FFFFFF;
  --badge-color-invert: #000000;
  --green-color: #00C800;
  --green-color-invert: #FF37FF;
  --gray-color: #808080;
  --foreground-color: #000000;
  --background-color: #FFFFFF;
  --cv-background-color: #D4D4D4;
  --title-color: #2a2f36;
  --foreground-color-dim: #2a2f36;
  --background-color-dim: #D0D0D0;
  --disabled-color: #404040;
  --black-color: #000000;
  --black-color-invert: #D0D0D0;
  --page-background-color: #FСFСFС;
  --page-background-color-invert: #303030;
  --hr-color: #F8F8F8;
  --hr-color-invert: #F0F0F0;
}

// Typography
.readable-typo {
  font-family: 'PT Serif', Georgia, Times, 'Times New Roman', serif;
}

.header-typo {
  font-family: 'PT Serif', Georgia, Times, 'Times New Roman', serif;
}

.accent-typo {
  color: var(--accent-color);
}

body, html {
  min-height: 100%;
}
