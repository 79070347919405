// -------------- THEME SWITCHER -------------- //
@mixin dark-appearance {
  filter: invert(1);
  .article img {
    filter: invert(1);
  }
  img {
    &.ioda { filter: invert(0); }
  }
}

body[a="dark"] { @include dark-appearance; }

@media (prefers-color-scheme: dark) {
  #top-h {
    background: var(--page-background-color-invert);
  }
  body {
    @include dark-appearance;
  }
  :root {
    --accent-color: var(--accent-color-invert);
    --accent-color-transparent: var(--accent-color-transparent-invert);
    --red-color: var(--red-color-invert);
    --green-color: var(--green-color-invert);
    --badge-color: var(--badge-color-invert);
    --black-color: var(--black-color-invert);
    --page-background-color: var(--page-background-color-invert);
    --hr-color: var(--hr-color-invert);
  }
}

// -------------------------------------------- //