
.article-typo {
  @extend .readable-typo;
  font-size: 16px;
}

.article-stress-typo {
  @extend .readable-typo;
  font-size: 16px;
  /* font-weight: 300; */
  font-style: italic;
}

.tags-typo {
  @extend .header-typo;
  color: #6c7a89;
  line-height: 1.6;
  /*font-weight: 400; */
  font-size: 19px;
}

.excerpt-typo {
  color: black;
  font-size: 120% !important;
  /* font-weight: 300 !important; */
}

.excerpt-typo-mini {
  color: var(--foreground-color-dim);
  font-size: 120% !important;
  /* font-weight: 300 !important; */
}

.disabled-link {
  color: var(--disabled-color);
}

.global-link {
  text-decoration: none;
  color: var(--title-color);
  font-weight: 600;
  border-bottom: solid 2px var(--accent-color);
}

.global-cv-link {
  text-decoration: none;
  color: var(--title-color);
  font-weight: 400;
  border-bottom: solid 1px var(--accent-color-transparent);
}

.global-link:visited {
  @extend .global-link;
}

.global-link:hover {
  color: var(--accent-color);
  border-bottom: solid 2px var(--accent-color);
}

.menu-link {
  color: var(--foreground-color);
  text-decoration: none;
  font-weight: 400;
  font-size: 18px;
  min-width: 48px;
  min-height: 48px;
}

@media screen and (max-width: 1400px) {
    .menu-link {
        font-size: 16px;
    }
}

.menu-link:hover {
  text-decoration: none;
  border-bottom: solid 4px var(--accent-color);
}

