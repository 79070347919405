// bg color is also needed in html in order to
// block body's background propagation
// see: https://stackoverflow.com/a/61265706
html {
  background: var(--page-background-color);
  margin: 0;
}

html { height: 100%; }

.page-content-main {
  max-width: 1024px;
  margin: 0 auto;
  padding: 4rem 2rem;
}

/* 
body {
  color: #4d4b57;
  font-family: Muli,serif;
  font-size: 18px;
  line-height: 1.4;
  margin: 0;
  min-height: 100%;
  overflow-wrap: break-word;
}

.post-meta { text-align: right; }

h1 {
  font-size: 1.5em;
}
h2 {
  font-size: 1.2em;
}
h2 {
  font-size: 1.0em;
}
h2, h3, h4, h5, h6 { margin-top: 3rem; }

hr { margin: 2rem 0; }

.article p {
  margin: 1rem 0;
  color: #4d4b57;
  font-weight: 400;
}

li { margin: 0.4rem 0; }

*:target { background: yellow; }

hr {
  color: var(--hr-color);
  text-align: center;
  border: 0;

  &:before { content: '/////' }
  &:after { content: attr(data-content) '/////' }
}

.project_entry {
  color: black;
  font-color: gray;
  list-style: none;
  margin-top: 0;
  padding-left: 0;

  li {
    display: inline;
  }
}

.project_entry_active {
  color: red;
}


*/